<template>
  <b-modal
    id="efetuarRetirada"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="lg"
    title="Efetuar Retirada"
  >
    <div>
      <b-row class="my-1">
        <b-col md="5">
          Cliente: <strong>{{ dadosCliente.item.cliente }} </strong>
        </b-col>
        <b-col>
          Conta: <strong>{{ dadosCliente.item.conta_nome }} </strong></b-col
        >
        <b-col>
          Cod. Banco: <strong>{{ dadosCliente.contas.cod_banco }} </strong>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col md="5">
          Agência: <strong>{{ dadosCliente.contas.num_agencia }}</strong>
        </b-col>
        <b-col>
          Nº Conta: <strong>{{ dadosCliente.contas.num_conta }} </strong>
        </b-col>
        <b-col>
          Chave Pix: <strong>{{ dadosCliente.contas.chave_pix }} </strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <label>Nº Comprovante:</label>
          <b-form-input type="number" size="sm" v-model="dadosCliente.item.num_comprovante" />
        </b-col>
        <b-col>
          <label>Upload Comprovante:</label>
          <b-form-file
            disabled
            placeholder="Selecione"
            v-model="upload"
            multiple
            size="sm"
            accept="image/*, .pdf, .zip, .dcm"
            browse-text="Upload"
          />
        </b-col>
        <b-col>
          <label>Valor:</label>
          <b-input-group size="sm">
            <b-input-group-prepend is-text> R$ </b-input-group-prepend>
            <b-form-input
              disabled
              :value="dadosCliente.item.valor | formataMoeda"
            />
          </b-input-group>
        </b-col>
      </b-row>
    </div>
    <hr />
    <b-row align-h="end">
      <b-button
        variant="outline-secondary"
        @click="$bvModal.hide('efetuarRetirada')"
        >Fechar</b-button
      >
      <b-button variant="success" class="mx-1" @click="efetuarRetirada()"
        >Efetuar</b-button
      >
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormFile,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BModal,
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormFile,
  },
  props: ["dadosCliente"],
  data() {
    return {
      upload: [],
      num_comprovante: "",
    };
  },

  computed: {},
  methods: {
    efetuarRetirada() {
      let payload = {
        retirada_id: this.dadosCliente.item.id,
        num_comprovante: this.num_comprovante,
      };
      this.$http.post("adm/retiradas", payload).then((res) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Retirada Efetuada com Sucesso!",
            icon: "InfoIcon",
            variant: "success",
          },
        });
        this.$bvModal.hide('efetuarRetirada')
        this.$store.dispatch('retiradas/listaRetiradas')
        this.$emit('concluido')
      });
    },
  },
  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>

<style>
</style>