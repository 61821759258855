<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <statistic-card-horizontal
          icon="FlagIcon"
          color="warning"
          :statistic="pedidosPendentes.length > 0 ? pedidosPendentes.length : 0"
          statistic-title="Pedidos Pendentes"
          class="mb-0"
        >
          <b-col slot="botaoVer" class="mt-1 pt-1">
            <b-button
              size="sm"
              class="btn-icon"
              variant="warning"
              @click="clicouVerPendentes"
            >
              ver
            </b-button>
          </b-col>
        </statistic-card-horizontal>
      </b-col>
      <b-col>
        <statistic-card-horizontal
          icon="FlagIcon"
          color="danger"
          :statistic="pedidosAtrasados.length > 0 ? pedidosAtrasados.length : 0"
          statistic-title="Pedidos Atrasados"
          class="mb-0"
        >
          <b-col slot="botaoVer" class="mt-1 pt-1">
            <b-button
              size="sm"
              class="btn-icon"
              variant="danger"
              @click="clicouVerAtrasados"
            >
              ver
            </b-button>
          </b-col>
        </statistic-card-horizontal>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col lg="5" md="5">
          <b-form-group
            label="Tipo Data"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="tipo"
                v-model="data_tipo"
                :options="tipo_data"
                text-field="text"
                value-field="value"
                style="height: 32px"
                @change="alterouTipoData"
              >
              </b-form-select>
              <b-form-datepicker
                id="data_inicio"
                size="sm"
                v-model="data_inicio"
                :date-format-options="{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }"
                close-button
                weekday-header-format="narrow"
                offset="-25"
                dropdown
                hide-header
                label-close-button="Fechar"
                label-reset-button="Apagar Data"
                labelHelp=""
                locale="pt-BR"
                placeholder="Data"
                reset-button
                @input="alterouData"
              />
              <b-form-datepicker
                id="data_final"
                size="sm"
                v-model="data_final"
                :date-format-options="{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                }"
                close-button
                hide-header
                weekday-header-format="narrow"
                offset="-25"
                dropdown
                label-close-button="Fechar"
                label-reset-button="Apagar Data"
                labelHelp=""
                locale="pt-BR"
                placeholder="Data"
                reset-button
                @input="alterouData"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Cliente" label-for="cliente" label-size="sm">
            <v-select
              style="background-color: white"
              id="cliente"
              v-model="empresa_id"
              :reduce="(empresas) => empresas.id"
              label="nome"
              class="select-size-sm"
              :options="empresas"
              @input="alterouCliente"
            />
            <div slot="no-options">não encontrado!</div>
          </b-form-group>
        </b-col>
        <b-col>
          <label for="procurar">Procurar</label>
          <b-input-group size="sm">
            <b-form-input v-model="filter" type="search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="px-1">
        <b-table
          responsive
          hover
          :items="retiradas"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :busy="busy"
          show-empty
        >
          <template #empty>
            <h4 class="text-center text-primary">
              nenhum resultado encontrado
            </h4>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Aguarde...</strong>
            </div>
          </template>

          <template #cell(data_pedido)="data">
            {{ data.item.data_pedido | converteDataHora }}
          </template>

          <template #cell(data_deposito)="data">
            {{ data.item.data_deposito | converteDataHora }}
          </template>

          <template #cell(valor)="data">
            {{ data.item.valor | formataMoeda }}
          </template>
          <template #cell(status)="data">
            <b-badge v-if="data.item.data_deposito" variant="success" href="#" @click="detalhesDeposito(data.item)">Depositado</b-badge>
            <b-badge v-else variant="warning" href="#" @click="efetuarRetirada(data.item)">Pendente</b-badge>
          </template>
          <template #cell(opcoes)>
            
          </template>
        </b-table>
      </b-row>
    </b-card>
    <modal-efetuar-retirada :dadosCliente="dadosCliente" />
    <modal-detalhes-deposito :dadosCliente="dadosCliente"/>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormSelect,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BAlert,
  BBadge,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import moment from "moment";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import ModalEfetuarRetirada from "./ModalEfetuarRetirada.vue";
import ModalDetalhesDeposito from './ModalDetalhesDeposito.vue';
export default {
  components: {
    BCard,
    BFormGroup,
    BFormSelect,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BBadge,
    BTable,
    BSpinner,
    BAlert,
    vSelect,
    StatisticCardHorizontal,
    ModalEfetuarRetirada,
    BModal,
    VBModal,
    ModalDetalhesDeposito,
  },
  directives: {
    "b-modal": VBModal,
  },

  data() {
    return {
      dadosCliente: {
        item: {
          cliente: '',
          valor: 0,
          num_comprovante: '',
          data_deposito: '',
        },
        contas: {
          nome: '',
          cod_banco: '',
          num_agencia: '',
          num_conta: '',
          chave_pix: '',
        }
      },
      tipo_pedido: 1,
      data_tipo: 1,
      data_inicio: this.$getDate.primeiroDiaMes(),
      data_final: this.$getDate.ultimoDiaMes(),
      empresa_id: null,
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        { key: "data_pedido", label: "Data Pedido", sortable: true },
        { key: "cliente", label: "Cliente", sortable: true },
        { key: "num_comprovante", label: "Comprovante", sortable: true },
        { key: "data_deposito", label: "Data Depósito", sortable: true },
        { key: "valor", label: "Valor", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "opcoes", label: "Opções", thStyle: { width: "18%" } },
      ],
      tipo_data: [
        { value: 1, text: "Data Pedido" },
        { value: 2, text: "Data Depósito" },
      ],
    };
  },

  mounted() {
    this.$store.dispatch("retiradas/listaRetiradas");
    this.$store.dispatch("empresas/listaEmpresas");
    this.totalRows = this.retiradas.length;
  },

  computed: {
    ...mapState({
      retiradas: (state) => state.retiradas.retiradas,
      pedidosPendentes: (state) => state.retiradas.pedidosPendentes,
      empresas: (state) => state.empresas.empresas,
      busy: (state) => state.retiradas.busy,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    pedidosAtrasados() {
      if (this.pedidosPendentes) {
        return this.pedidosPendentes.filter(
          (res) =>
            res.data_pedido < this.$getDate.subDias(this.$getDate.hoje(), 2)
        );
      } else {
        return [];
      }
    },
  },

  methods: {
    clicouVerPendentes() {
      this.data_tipo = 1;
      let payload = {
        data_inicio: "",
        data_final: "",
      };
      this.$store.commit("retiradas/STORE_FILTRO_DATA", payload);
      this.$store.commit("retiradas/STORE_RETIRADAS_CLIENTES", 0);
      this.alterouTipoData();
    },
    clicouVerAtrasados() {
      this.data_tipo = 1;
      let payload = {
        data_inicio: "2022-01-01",
        data_final: this.$getDate.subDias(this.$getDate.hoje(), 2),
      };
      this.$store.commit("retiradas/STORE_FILTRO_DATA", payload);
      this.$store.commit("retiradas/STORE_RETIRADAS_CLIENTES", 0);
      this.alterouTipoData();
    },
    alterouTipoData() {
      this.$store.commit("retiradas/STORE_TIPO_DATA", this.data_tipo);
      this.$store.dispatch("retiradas/listaRetiradas");
    },
    alterouData() {
      let payload = {
        data_inicio: this.data_inicio,
        data_final: this.data_final,
      };
      this.$store.commit("retiradas/STORE_FILTRO_DATA", payload);
      this.$store.dispatch("retiradas/listaRetiradas");
    },
    alterouCliente() {
      if (this.empresa_id === null) {
        this.empresa_id = 0;
      }
      this.$store.commit("retiradas/STORE_RETIRADAS_CLIENTES", this.empresa_id);
      this.$store.dispatch("retiradas/listaRetiradas");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
    },
    efetuarRetirada(item) {
      this.$http.get('adm/retiradas/contas/'+item.id).then(res => {
        let contas = res.data[0]
        let payload = {
          item,
          contas
        }
        this.dadosCliente = payload
      })
      this.$bvModal.show("efetuarRetirada");
    },
    detalhesDeposito(item) {
      this.$http.get('adm/retiradas/contas/'+item.id_company).then(res => {
        let contas = res.data
        let payload = {
          item,
          contas
        }
        this.dadosCliente = payload
      })
      this.$bvModal.show("detalhesDeposito");
    }
  },

  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
    converteDataHora(data) {
      if (!data) return;

      return moment(data).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style>
</style>