<template>
  <b-modal
    id="detalhesDeposito"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="lg"
    title="Detalhes da Retirada"
  >
    <div>
      <b-row class="my-1">
        <b-col md="5">
          Cliente: <strong>{{ dadosCliente.item.cliente }} </strong>
        </b-col>
        <b-col>
          Conta: <strong>{{ dadosCliente.contas.nome }} </strong></b-col
        >
        <b-col>
          Cod. Banco: <strong>{{ dadosCliente.contas.cod_banco }} </strong>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col md="5">
          Agência: <strong>{{ dadosCliente.contas.num_agencia }}</strong>
        </b-col>
        <b-col>
          Nº Conta: <strong>{{ dadosCliente.contas.num_conta }} </strong>
        </b-col>
        <b-col>
          Chave Pix: <strong>{{ dadosCliente.contas.chave_pix }} </strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          Data e Hora Depósito: <strong>{{ dadosCliente.item.data_deposito | converteDataHora }} </strong>
        </b-col>
        <b-col>
          Nº Comprovante: <strong>{{ dadosCliente.item.num_comprovante }} </strong>
        </b-col>
        <b-col>
          Valor: <strong>R$ {{ dadosCliente.item.valor | formataMoeda }} </strong>
        </b-col>
      </b-row>
    </div>
    <hr />
    <b-row align-h="end">
      <b-button
        class="mx-1"
        variant="outline-secondary"
        @click="$bvModal.hide('detalhesDeposito')"
        >Fechar</b-button
      >
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import moment from 'moment'
export default {
  components: {
    BModal,
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: ["dadosCliente"],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    converteDataHora(data) {
      if (!data) return;

      return moment(data).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>


<style>
</style>